.loginLayout {
  background-color: #f2f2f2;
  height: 100vh;
  padding-top: 50px; }
  .loginLayout .header {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100vw;
    background: linear-gradient(90deg, #f7f7f7 0%, white 58%);
    background: -moz-linear-gradient(90deg, #f7f7f7 0%, white 58%);
    background: -webkit-linear-gradient(90deg, #f7f7f7 0%, white 58%);
    background: -ms-linear-gradient(90deg, #f7f7f7 0%, white 58%);
    box-shadow: 0px 1px 4px 0px rgba(44, 44, 44, 0.35); }
    .loginLayout .header a {
      padding-left: 15px; }
  .loginLayout .container {
    top: 50%;
    position: relative;
    transform: translateY(-60%); }
