/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.inline {
  margin: -1px 0 0 0 !important; }
  .inline input {
    border-bottom-left-radius: 0.28571429rem !important;
    border-bottom-right-radius: 0.28571429rem !important; }

.vdivide {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff; }

.order-form {
  padding-bottom: 80px; }
  .order-form .form-group {
    margin-bottom: 0; }
  .order-form > div {
    padding: 15px 15px 2px 15px; }
  .order-form input {
    padding-top: 12px; }
  .order-form > div:last-child {
    margin-bottom: 0;
    padding: 15px; }
  .order-form .checkboxTop {
    margin-bottom: 0 !important; }
    .order-form .checkboxTop input {
      position: absolute !important;
      background: transparent; }
    .order-form .checkboxTop label {
      color: #000;
      font-size: 12px;
      vertical-align: middle;
      z-index: 2;
      padding: 8px 16px !important;
      background: #fff;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc; }
      .order-form .checkboxTop label:after, .order-form .checkboxTop label:before {
        display: none !important; }
    .order-form .checkboxTop input:focus + label {
      border-color: #87bc2f; }
    .order-form .checkboxTop:first-child label {
      border-left: 1px solid #ccc;
      border-top-left-radius: 0.28571429rem;
      border-bottom-left-radius: 0.28571429rem; }
    .order-form .checkboxTop:nth-child(2) label {
      border-right: 1px solid #ccc;
      border-top-right-radius: 0.28571429rem;
      border-bottom-right-radius: 0.28571429rem; }
    .order-form .checkboxTop.checked label {
      background: #87bc2f;
      color: #fff !important; }
  .order-form .closebutton {
    float: right !important; }

.textlink {
  background: transparent;
  border: none;
  color: #808080;
  float: left;
  padding: 7px 11px;
  cursor: pointer; }
  .textlink:hover {
    text-decoration: underline;
    background: transparent;
    border: none; }

.add-additional-info {
  color: #888;
  padding: 6px 6px 6px;
  font-size: 12px;
  display: block; }
  .add-additional-info a {
    color: #888;
    display: inline-block; }
  .add-additional-info span {
    margin-left: 10px; }

.name-and-number-group > div {
  vertical-align: top;
  display: inline-block;
  margin-top: -1px; }

.name-and-number-group > div:first-child {
  width: 50%; }
  .name-and-number-group > div:first-child input {
    border-top-left-radius: 0.28571429rem; }

.name-and-number-group > div:nth-child(3) {
  width: calc(50% - 43px);
  margin-right: -2px; }
  .name-and-number-group > div:nth-child(3) input {
    border-top-right-radius: 0.28571429rem;
    left: -1px;
    position: relative;
    border-left: 1px solid #fff; }

.name-and-number-group > div:last-child {
  width: 100%; }

.name-and-number-group .form-control {
  width: 100%; }

.meta-container {
  width: 100%;
  margin-top: 10px; }
  .meta-container input {
    border-top-left-radius: 0.28571429rem !important;
    border-top-right-radius: 0.28571429rem !important;
    border-bottom-left-radius: 0.28571429rem !important;
    border-bottom-right-radius: 0.28571429rem !important; }

.ext1-ext2-group {
  margin-top: 0px !important; }
  .ext1-ext2-group .form-group {
    width: 100%; }
  .ext1-ext2-group .form-group:nth-child(1) input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0.28571429rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .ext1-ext2-group .form-group:nth-child(2) input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.28571429rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0; }

.dropdown-container {
  position: relative;
  display: inline-block;
  padding-top: 0;
  width: 46px;
  min-width: 46px !important;
  margin-left: -1px;
  margin-right: -1px;
  border-radius: 0 !important;
  border-color: #ccc; }
  .dropdown-container i {
    padding-top: 17px !important;
    padding-bottom: 0 !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    right: 10px !important; }
  .dropdown-container > div {
    width: 100%;
    border-radius: 0 !important;
    min-width: 0 !important;
    padding-top: 17px !important;
    padding-bottom: 0 !important;
    padding-left: 4px !important; }
  .dropdown-container .hiddenlabel-sup {
    font-size: 0.7em;
    left: 5px;
    top: 1px;
    transition: 0.5s all;
    z-index: 10;
    padding-top: 0 !important;
    display: block;
    position: absolute;
    color: #333;
    pointer-events: none;
    color: #9e9e9e; }

.timeslot {
  margin-top: 10px; }

.timeslot-single > div > div:first-child {
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0; }

/* Hover color for all elements in a category */
.sender-address label,
.recipient-address label {
  padding-left: 6px;
  padding-bottom: 2px; }

.sender-address:hover .repetition-wrapper .day_chooser:checked + .day_chooser_label,
.recipient-address:hover .repetition-wrapper .day_chooser:checked + .day_chooser_label {
  background: #87bc2f; }

.checkbox_wrapper {
  margin-top: 15px;
  margin-left: 5px; }
  .checkbox_wrapper > div:first-child {
    margin-right: 10px; }

.repetitive_task_wrapper {
  width: 100%; }
  .repetitive_task_wrapper > .repetitive_task {
    width: 100% !important; }
  .repetitive_task_wrapper div:nth-child(2) {
    margin-right: 10px; }

.repetition-wrapper {
  display: flex;
  flex-wrap: no-wrap;
  clear: both;
  margin-top: 10px; }
  .repetition-wrapper:hover .day_chooser:checked + .day_chooser_label {
    background: #87bc2f;
    color: #fff;
    transition: 0.275s all; }
  .repetition-wrapper.disabled {
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0; }

.repetitive_switch_wrapper {
  float: right;
  position: relative; }
  .repetitive_switch_wrapper .repetitive_label {
    display: inline-block; }
  .repetitive_switch_wrapper .repetitive_switch {
    margin-right: 5px; }

.repetitive_switch {
  display: inline-block;
  font-size: 14px; }
  .repetitive_switch label {
    font-size: 14px !important;
    padding-left: 40px !important;
    top: 0px;
    left: 5px;
    line-height: 10px; }
  .repetitive_switch.disabled input:focus ~ .box:before,
  .repetitive_switch.disabled input:focus ~ label:before,
  .repetitive_switch.disabled label:hover::before {
    background-color: rgba(0, 0, 0, 0.05) !important; }

.repetition-dropdown {
  flex: 1 1 auto;
  height: 38px;
  min-width: 90px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.repetition-dropdown-fixed {
  flex: 1 1 40%;
  height: 38px;
  background: white;
  border-top-left-radius: 0.28571429rem;
  padding: 10px 5px;
  border: 1px solid #ccc;
  border-right: 1px solid transparent; }
  .repetition-dropdown-fixed.disabled {
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0; }

.weekdays {
  flex: 1 1 60%; }
  .weekdays.disabled .day_chooser_label {
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    cursor: default; }
    .weekdays.disabled .day_chooser_label:hover, .weekdays.disabled .day_chooser_label:focus {
      border-color: #ccc !important;
      background-color: transparent;
      color: #8c8c8c; }
  .weekdays.disabled .day_chooser_label_checked:hover, .weekdays.disabled .day_chooser_label_checked:focus {
    color: #000; }

.day_chooser {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  display: none; }

.day_chooser_label {
  width: 14.285714286%;
  height: 38px;
  display: inline-block;
  background: #ddd;
  color: #8c8c8c;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0 !important;
  border-radius: 0;
  margin-left: 0;
  line-height: 35px;
  vertical-align: middle;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  border: 1px solid #ccc;
  border-right: 0;
  cursor: pointer; }
  .day_chooser_label:last-of-type {
    border-right: 1px solid #ccc;
    border-top-right-radius: 0.28571429rem !important; }
  .day_chooser_label:focus {
    outline: none;
    border-top-color: #87bc2f;
    border-bottom-color: #87bc2f;
    color: #000; }

.day_chooser_label_checked {
  background: #fff;
  color: #000; }
  .day_chooser_label_checked:focus {
    border-top-color: #87bc2f;
    border-bottom-color: #87bc2f;
    color: #000; }

.day_chooser:checked + .day_chooser_label {
  background: #d7d7d7;
  color: #000;
  transition: 0.275s all; }

.react-datepicker-wrapper {
  width: 100%; }

.additional-info-field {
  display: block;
  font-size: 13px;
  border: 1px solid #ccc;
  color: #555555;
  padding: 6px;
  width: 100%;
  resize: none;
  border-radius: 0.28571429rem;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  outline: none; }
  .additional-info-field.disabled {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #ccc;
    min-height: 30px; }
  .additional-info-field::placeholder {
    color: #9f9f9f; }

.label-custom {
  display: block;
  color: #9e9e9e;
  font-size: 0.7em;
  margin-left: 5px;
  margin-bottom: -6px; }
