/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.shipment {
  margin-left: -6;
  margin-right: -6;
  border-bottom: 1.5px dotted #ccc;
  padding: 5px 14px 5px 8px;
  /*
  &:hover {
      background-color: $table-bg-hover;
  }
  */
  min-height: 49px; }
  .shipment:before, .shipment:after {
    content: " ";
    display: table; }
  .shipment:after {
    clear: both; }
  .shipment.selected {
    background-color: #ffffcc; }

.shipment-selection {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 0 0 3px;
  padding: 0; }
  @media (min-width: 992px) {
    .shipment-selection {
      float: left;
      width: 0.83333%; } }

.shipment-category {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 0 0 15px;
  padding: 0; }
  @media (min-width: 992px) {
    .shipment-category {
      float: left;
      width: 10.41667%; } }

.shipment-sender,
.shipment-recipient,
.shipment-address {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  padding: 0; }
  @media (min-width: 992px) {
    .shipment-sender,
    .shipment-recipient,
    .shipment-address {
      float: left;
      width: 37.5%; } }

.shipment-notes {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  padding: 0;
  margin: 0 0 0 3px; }
  @media (min-width: 992px) {
    .shipment-notes {
      float: left;
      width: 16.66667%; } }

.shipment-notes-row {
  flex: 1; }

.shipment-responsible {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  padding: 0; }
  @media (min-width: 992px) {
    .shipment-responsible {
      float: left;
      width: 16.66667%; } }

.shipment-status {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  padding: 0; }
  @media (min-width: 992px) {
    .shipment-status {
      float: left;
      width: 8.33333%; } }

.status-label {
  text-transform: uppercase;
  font-weight: 400 !important; }

.shipment-action {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 2px; }
  @media (min-width: 992px) {
    .shipment-action {
      float: left;
      width: 4.16667%; } }

.more-button {
  border: none !important;
  content: none; }

.whitespace-recurring {
  display: inline-block;
  width: 14px;
  height: 14px; }
