.datepicker-wrapper {
  position: relative;
  height: 38px; }
  .datepicker-wrapper .link {
    padding: 5px;
    display: block;
    float: left;
    height: 38px;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-top: 0;
    line-height: 27px;
    border-bottom-right-radius: 0.28571429rem;
    border-bottom-left-radius: 0.28571429rem; }
    .datepicker-wrapper .link.small {
      width: calc(50% + 1px);
      margin-left: -1px;
      border-bottom-right-radius: 0.28571429rem;
      border-bottom-left-radius: 0; }
      .datepicker-wrapper .link.small.start {
        width: 50%;
        margin-left: 0;
        border-bottom-left-radius: 0.28571429rem;
        border-bottom-right-radius: 0; }
    .datepicker-wrapper .link:focus span {
      text-decoration: underline; }
    .datepicker-wrapper .link img {
      position: relative;
      margin-right: 6px;
      margin-left: 2px;
      top: -2px; }
    .datepicker-wrapper .link span {
      cursor: pointer;
      color: #9f9f9f;
      font-size: 13px; }
      .datepicker-wrapper .link span:hover {
        text-decoration: underline; }
      .datepicker-wrapper .link span.additional {
        float: right;
        font-size: 12px; }
  .datepicker-wrapper.date .link {
    width: 50%;
    position: relative;
    border-left: 0;
    border-bottom-right-radius: 0.28571429rem;
    border-bottom-left-radius: 0; }
  .datepicker-wrapper .datepicker {
    position: relative;
    display: block;
    float: left;
    height: 38px;
    width: 50%;
    vertical-align: top; }
    .datepicker-wrapper .datepicker:first-child {
      border-bottom-left-radius: 0.28571429rem;
      border-bottom-right-radius: 0;
      border-right: 0; }
    .datepicker-wrapper .datepicker:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0.28571429rem; }
      .datepicker-wrapper .datepicker:last-child .combined {
        border-left: 0; }
    .datepicker-wrapper .datepicker:hover .clearbutton {
      opacity: 1; }
    .datepicker-wrapper .datepicker.duedate div > input {
      border-right-color: #fff; }
    .datepicker-wrapper .datepicker .hiddenlabel-sup {
      position: absolute;
      top: 0px;
      left: 5px;
      font-size: 0.7em;
      color: #9e9e9e; }
    .datepicker-wrapper .datepicker .react-datepicker__input-container {
      width: 100%; }
    .datepicker-wrapper .datepicker .react-datepicker__tether-enabled + .hiddenlabel-sup {
      color: #87bc2f; }
    .datepicker-wrapper .datepicker input[type="text"] {
      width: 100%;
      padding: 0;
      padding-top: 12px;
      padding-left: 4px;
      text-align: left;
      border: 1px solid #ccc;
      border-top: 0;
      text-align: left;
      line-height: 100%;
      vertical-align: middle;
      height: 38px; }
      .datepicker-wrapper .datepicker input[type="text"].startDate {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0.28571429rem; }
      .datepicker-wrapper .datepicker input[type="text"].endDate {
        border-left: 0; }
    .datepicker-wrapper .datepicker.repetitive_false.date input[type="text"] {
      border-right: 1px solid #fff; }
  .datepicker-wrapper.allEmpty {
    display: none; }
  .datepicker-wrapper.disabled .link.disabled.allEmpty, .datepicker-wrapper.disabled .link.disabled.otherEmpty, .datepicker-wrapper.disabled .link.disabled.empty {
    background-color: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important; }
  .datepicker-wrapper.disabled .link.disabled.otherEmpty.empty {
    display: none; }
  .datepicker-wrapper.disabled .link.disabled.allEmpty {
    height: 38px; }
    .datepicker-wrapper.disabled .link.disabled.allEmpty img,
    .datepicker-wrapper.disabled .link.disabled.allEmpty span {
      display: none; }
  .datepicker-wrapper.disabled .datepicker .clearbutton {
    display: none; }
  .datepicker-wrapper .combined:first-child {
    border-right: 0; }
  .datepicker-wrapper .combined:last-child {
    border-left: 0; }

.clearbutton {
  position: absolute;
  opacity: 0;
  top: 11px;
  right: 7px;
  border-radius: 50%;
  color: #fff;
  background: #bfbfbf;
  height: 15px;
  width: 15px;
  font-size: 1.1rem;
  line-height: 13.5px;
  vertical-align: middle;
  padding-left: 4px;
  padding-top: 1px;
  cursor: pointer;
  transition: 0.5s background;
  transition: 0.2s opacity; }
  .clearbutton:hover {
    background: #9e9e9e; }
