/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.messagebox {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 992px) {
    .messagebox {
      float: left;
      width: 20.83333%; } }
