/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.react-autosuggest__container {
  position: relative;
  display: block; }

.react-autosuggest__input {
  width: 100%;
  padding-bottom: 0;
  padding-top: 11px;
  padding-left: 4px;
  box-sizing: border-box;
  line-height: 28px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-bottom-right-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem; }

.react-autosuggest__input:focus {
  outline: none; }

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-bottom-right-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding-left: 5px;
  box-sizing: border-box;
  line-height: 28px;
  font-weight: 300;
  font-size: 14px; }

.react-autosuggest__suggestion--focused {
  background-color: #87bc2f;
  font-weight: bold;
  color: #fff; }
