/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.ci-trackpanel-container {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 992px) {
    .ci-trackpanel-container {
      float: left;
      width: 33.33333%; } }

.ci-trackpanel {
  min-height: 200px;
  box-shadow: none !important; }
