/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.direct-chat-msg,
.direct-chat-text {
  display: block; }

.direct-chat-msg {
  margin-bottom: 10px; }

.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table; }

.direct-chat-msg:after {
  clear: both; }

.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #bcc7d6;
  margin: 2px 0 0 15px;
  color: #444; }

.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #bcc7d6;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none; }

.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px; }

.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px; }

.right-msg .direct-chat-text {
  margin-right: 15px;
  margin-left: 0; }

.right-msg .direct-chat-text:after,
.right-msg .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de; }

.direct-chat-messages,
.direct-chat-contacts {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -moz-transition: -moz-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out; }

.right-msg > .direct-chat-text {
  background: #e0edff;
  border-color: #bcc7d6;
  color: #000; }

.right-msg > .direct-chat-text:after,
.right-msg > .direct-chat-text:before {
  border-left-color: #bcc7d6; }

.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0; }

.direct-chat.chat-pane-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow: auto; }

.direct-chat-messages,
.direct-chat-contacts {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -moz-transition: -moz-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out; }

.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px; }

.right-msg .direct-chat-img {
  float: right; }

.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px; }

.direct-chat-name {
  font-weight: 600; }

.direct-chat-timestamp {
  color: #999; }

.direct-chat-contacts-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

.direct-chat-contacts {
  -webkit-transform: translate(101%, 0);
  -ms-transform: translate(101%, 0);
  -o-transform: translate(101%, 0);
  transform: translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #fff;
  overflow: auto; }

.message-transaction-icons {
  margin-left: 5px; }

.message-time {
  margin-right: 15px;
  font-size: 10px; }
