.form-group.floatingtextfield {
  position: relative; }
  .form-group.floatingtextfield .hiddenlabel-main,
  .form-group.floatingtextfield .hiddenlabel-sup {
    display: block;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    color: #9e9e9e; }
  .form-group.floatingtextfield .hiddenlabel-main {
    font-size: 1em;
    left: 7px;
    top: 11px; }
  .form-group.floatingtextfield .hiddenlabel-main-small {
    top: 7px;
    font-size: 0.9em; }
  .form-group.floatingtextfield .hiddenlabel-main-large {
    top: 13px;
    font-size: 1.1em; }
  .form-group.floatingtextfield .hiddenlabel-sup {
    font-size: 0.7em;
    left: 5px;
    top: 5px;
    transition: 0.5s all; }
  .form-group.floatingtextfield input,
  .form-group.floatingtextfield select,
  .form-group.floatingtextfield .form-control {
    padding-bottom: 0;
    padding-top: 11px;
    padding-left: 4px;
    box-sizing: border-box;
    line-height: 28px; }
  .form-group.floatingtextfield select {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-appearance: none;
    padding-bottom: 5px !important;
    line-height: 12px !important; }
  .form-group.floatingtextfield.off .hiddenlabel-sup {
    top: 4.5px;
    opacity: 0; }
  .form-group.floatingtextfield.on .hiddenlabel-main {
    opacity: 0; }
  .form-group.floatingtextfield.on .hiddenlabel-sup {
    top: 3px; }
  .form-group.floatingtextfield.on.focus .hiddenlabel-sup,
  .form-group.floatingtextfield.on input:focus + .hiddenlabel-sup {
    color: #87bc2f !important; }
