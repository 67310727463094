.employeeSubForm {
  padding-top: 20px; }
  .employeeSubForm h4 {
    font-size: 14px;
    font-weight: normal;
    border-bottom: 1px solid black;
    display: inline-block;
    padding: 0px 0;
    margin-left: 5px;
    margin: -5px 0 5px 5px; }
  .employeeSubForm .title {
    display: block; }
  .employeeSubForm .titlecontainer {
    padding: 0px 17px 0px;
    margin: 0 auto; }
    .employeeSubForm .titlecontainer span {
      font-size: 16px;
      color: #000; }
  .employeeSubForm .user-id {
    float: left;
    width: 100%;
    margin-top: -35px;
    padding: 15px 15px 20px; }
    .employeeSubForm .user-id div:first-child {
      float: right;
      width: 100px; }
      .employeeSubForm .user-id div:first-child input {
        border-radius: 5px; }
  .employeeSubForm .fields {
    padding: 15px 15px 20px;
    margin: 0 auto; }
    .employeeSubForm .fields div {
      margin: -1px 0; }
    .employeeSubForm .fields div:first-child {
      display: inline-block;
      width: 50%; }
      .employeeSubForm .fields div:first-child input {
        border-top-left-radius: 5px; }
    .employeeSubForm .fields div:nth-child(2) {
      display: inline-block;
      width: calc(50% + 1px);
      margin-left: -1px; }
      .employeeSubForm .fields div:nth-child(2) input {
        border-top-right-radius: 5px; }
    .employeeSubForm .fields div:nth-child(3) input {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
  .employeeSubForm .password {
        padding: 0px 15px 20px;
        margin-top: -15px; }
      .employeeSubForm .password .checkbox{
        padding-left: 5px; }
        .employeeSubForm .password .form-group{
          margin-bottom: 0px; }
        .employeeSubForm .password .checkbox label{
            padding-left: 22px; }
  .employeeSubForm .textlink {
    background: transparent;
    border: none;
    color: #808080;
    float: left;
    padding: 7px 11px;
    cursor: pointer; }
    .employeeSubForm .textlink:hover {
      text-decoration: underline;
      background: transparent;
      border: none; }
  .employeeSubForm .checkboxes {
    float: right;
    width: 30%;
    padding: 0 10px 5px 0; }
    .employeeSubForm .checkboxes div {
      width: 100%; }
      .employeeSubForm .checkboxes div > span {
        padding-left: 15px;
        top: -2px;
        position: relative; }

.shipment-form-footer-employee {
  clear: both;
  clear: both;
  position: fixed;
  border-right: 1px solid #ccc;
  width: 100%; }
