/*
 * Component: ScrollList
 */
/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.scroll {
  display: block;
  overflow-y: scroll;
  border-bottom: 0;
  background: #fff; }

.entry {
  display: block;
  padding: 4px 7px 4px 7px;
  cursor: pointer; }
  .entry:hover {
    background-color: #efefef; }
    .entry:hover .menu {
      display: inline-block; }
  .entry .subTitle {
    color: #aaa;
    white-space: pre; }
  .entry .menu {
    float: right;
    display: none;
    margin-top: -30px; }
  .entry .more-button {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    cursor: pointer; }
  .entry .more-button:hover {
    background: #fff !important; }
  .entry .action {
    float: right;
    color: #aaa; }
  .entry .clear {
    clear: both; }

.entrySelected {
  background-color: #dcdcdc; }
  .entrySelected:hover {
    background-color: #c3c3c3; }
  .entrySelected .subTitle {
    opacity: 0.8; }

.navigationRow {
  width: 100%;
  text-align: right;
  padding: 10px 8px 10px 0; }
  .navigationRow button {
    margin: -1px -1px 0 0;
    border-radius: 0;
    border: none; }
    .navigationRow button:last-child {
      margin: -1px 0 0 0;
      border-left: 1px solid #ccc; }
