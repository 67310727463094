.loginLayout .button {
  margin: 20px 0;
  width: 100%; }

.loginLayout .introtext {
  padding-bottom: 20px;
  font-size: 1.1em;
  font-weight: 600;
  text-align: center;
  margin: 0;
  color: #000; }

.loginLayout .infotext {
  color: #8a8a8a;
  font-size: 14px;
  padding-bottom: 20px;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  display: block; }

.loginLayout .error {
  color: #b33333;
  font-size: 1em;
  padding: 20px 0; }

.loginLayout input[type="email"],
.loginLayout input[type="number"],
.loginLayout input[type="search"],
.loginLayout input[type="text"],
.loginLayout input[type="tel"],
.loginLayout input[type="url"],
.loginLayout input[type="password"],
.loginLayout textarea,
.loginLayout select {
  line-height: 28px;
  font-size: 14px;
  height: auto;
  border-radius: 0;
  padding: 8px 20px;
  outline: none;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.28571429rem;
  outline: none; }
  .loginLayout input[type="email"]:focus,
  .loginLayout input[type="number"]:focus,
  .loginLayout input[type="search"]:focus,
  .loginLayout input[type="text"]:focus,
  .loginLayout input[type="tel"]:focus,
  .loginLayout input[type="url"]:focus,
  .loginLayout input[type="password"]:focus,
  .loginLayout textarea:focus,
  .loginLayout select:focus {
    border: 1px solid #ccc;
    outline: none; }

.passwordField {
  display: block !important;
  text-align: left;
  max-width: 280px;
  margin: 0 auto; }
  .passwordField form {
    width: 100%;
    margin: 40px auto;
    background: #f1f1f1;
    border-radius: 5px;
    padding: 3em 2em;
    color: #555; }
  .passwordField fieldset {
    border: 0;
    max-width: 580px;
    margin: 0 auto; }
  .passwordField label {
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    padding-top: 0;
    margin-top: 0; }
  .passwordField .passwordField {
    width: 100%; }
  .passwordField .passwordField__strength {
    color: #999;
    height: 3px; }
  .passwordField .passwordField__icon {
    display: none !important; }
