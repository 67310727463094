.modal-body {
  padding: 0 !important; }

.modal-body .panel.panel-default {
  background-color: #fff;
  border-color: transparent;
  margin: 15px;
  border: none; }
  .modal-body .panel.panel-default .panel-heading {
    background: transparent;
    padding: 10px 20px 5px 20px;
    border: none; }
    .modal-body .panel.panel-default .panel-heading .panel-title {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px; }
  .modal-body .panel.panel-default .panel-body {
    padding-top: 5px; }
    .modal-body .panel.panel-default .panel-body .form-group .control-label {
      line-height: 40px;
      margin-bottom: 0;
      text-align: right; }

.tour-form-footer,
.shipment-form-footer {
  clear: both;
  position: fixed;
  width: 50% !important;
  margin-top: 17px;
  border-right: 1px solid #ccc; }
  @media screen and (max-width: 950px) {
    .tour-form-footer,
    .shipment-form-footer {
      width: 100%; } }
  .tour-form-footer .btn:first-child,
  .shipment-form-footer .btn:first-child {
    float: left; }

.Select-control {
  border-radius: 0 !important; }

.Select-control .Select-placeholder {
  padding-right: 16px; }

#ext_reference input,
#count_barcode_size input {
  margin-top: -1px; }

#ext_reference a.close,
#count_barcode_size a.close {
  text-decoration: none;
  margin-top: 10px;
  display: block; }

#ext_reference .packstuecke,
#count_barcode_size .packstuecke {
  width: 45px; }
  #ext_reference .packstuecke input,
  #count_barcode_size .packstuecke input {
    width: 100%; }

#ext_reference .barcode,
#count_barcode_size .barcode {
  width: 40%;
  margin-right: -1px; }
  #ext_reference .barcode input,
  #count_barcode_size .barcode input {
    width: 100%; }

#ext_reference .dimensions,
#count_barcode_size .dimensions {
  width: calc(15%);
  margin-right: -1px; }
  #ext_reference .dimensions input,
  #count_barcode_size .dimensions input {
    width: 100%; }

#ext_reference .weight,
#count_barcode_size .weight {
  width: 50px;
  margin-right: -1px; }
  #ext_reference .weight input,
  #count_barcode_size .weight input {
    width: 100%; }

#sender_address_additional,
#sender_avis,
#sender_email,
#pickup_datetime {
  margin-top: -1px; }
  #sender_address_additional a.close,
  #sender_avis a.close,
  #sender_email a.close,
  #pickup_datetime a.close {
    text-decoration: none;
    margin-top: 10px;
    display: block; }
  #sender_address_additional .notizen,
  #sender_address_additional .email,
  #sender_address_additional .ext-address,
  #sender_avis .notizen,
  #sender_avis .email,
  #sender_avis .ext-address,
  #sender_email .notizen,
  #sender_email .email,
  #sender_email .ext-address,
  #pickup_datetime .notizen,
  #pickup_datetime .email,
  #pickup_datetime .ext-address {
    width: 100%; }
    #sender_address_additional .notizen input,
    #sender_address_additional .email input,
    #sender_address_additional .ext-address input,
    #sender_avis .notizen input,
    #sender_avis .email input,
    #sender_avis .ext-address input,
    #sender_email .notizen input,
    #sender_email .email input,
    #sender_email .ext-address input,
    #pickup_datetime .notizen input,
    #pickup_datetime .email input,
    #pickup_datetime .ext-address input {
      width: 100%; }
