/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.ci-spinner-container {
  margin: 1em;
  text-align: center; }

.ci-spinner {
  width: 48px;
  height: 48px;
  margin-top: 35px; }
  .ci-spinner :global .react-spinner_bar {
    background-color: #919191; }
