/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.rowNav {
  padding-right: 0 !important;
  border-right: 0;
  margin-right: -1px;
  margin-left: 1px;
  border-bottom: 0;
  height: 367px;
  background-clip: content-box;
  background-color: #edeeeb; }
  .rowNav > ul > li > a {
    border-radius: 0;
    border: none;
    border-bottom: 0 !important;
    color: #000;
    margin-right: 0;
    border-right: 0;
    color: #000; }
    .rowNav > ul > li > a span {
      display: inline-block;
      border-bottom: 1px solid transparent; }
    .rowNav > ul > li > a:focus {
      outline: none; }
    .rowNav > ul > li > a:hover {
      background-color: transparent; }
      .rowNav > ul > li > a:hover span {
        border-bottom: 1px solid #bbb; }
  .rowNav > ul > li.selected {
    margin-left: 1px; }
    .rowNav > ul > li.selected > a {
      padding-left: 14px;
      background-color: #eee;
      border: none; }
      .rowNav > ul > li.selected > a span {
        border-bottom: 1px solid #000; }

.rowList {
  padding-left: 0 !important;
  border-left: 1px solid #d6d7da; }
