.calendar-icon {
  display: inline-block;
  padding: 12px 10px 10px 10px;
  pointer-events: none;
  background: #eee;
  border: 1px solid #ccc;
  height: 36px;
  vertical-align: top;
  left: 0;
  margin-left: 0 !important; }

.calendar-input {
  position: relative;
  width: 108px;
  height: 36px;
  display: inline-block;
  left: -1px;
  text-align: center; }

.datePickerWrapper {
  display: inline-block; }

.datePickerWrapper:first-child {
  display: inline-block; }
