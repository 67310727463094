/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.mapping-form {
  padding-bottom: 80px; }

.shipment-form-footer {
  width: 100%; }

.shipment-type {
  padding-bottom: 15px; }
