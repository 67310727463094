/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.ci-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.ci-icon {
  float: left;
  padding-top: 0;
  color: #777777; }

.ci-icon-width:hover {
  margin-left: 11px !important; }
