/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.footer {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1100; }
  @media (min-width: 992px) {
    .footer {
      float: left;
      width: 87.5%; } }
