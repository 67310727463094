/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.shipment-list {
  margin-top: 60px;
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff;
  padding-bottom: 15px;
  overflow-y: auto;
  height: calc(100vh - 50px - 60px); }
  @media (min-width: 992px) {
    .shipment-list {
      float: left;
      width: 100%; } }

.ci-spinner-spacing {
  margin-top: 5.5em; }

.ci-empty-shipments {
  margin: 5.5em 1em 1em 1em; }
