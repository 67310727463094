.form-group {
  position: relative; }
  .form-group input,
  .form-group select,
  .form-group .form-control {
    padding-bottom: 6px;
    padding-left: 6px;
    box-sizing: border-box;
    line-height: 40px;
    border-radius: 0; }
  .form-group select {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-appearance: none;
    padding-bottom: 5px !important;
    line-height: 12px !important; }
  .form-group.off .hiddenlabel-sup {
    top: 4.5px;
    opacity: 0; }
  .form-group.on .hiddenlabel-main {
    opacity: 0; }
  .form-group.on .hiddenlabel-sup {
    top: 2.5px; }
  .form-group.on.focus .hiddenlabel-sup,
  .form-group.on.focus .hiddenlabel-main,
  .form-group.on input:focus + .hiddenlabel-sup,
  .form-group.on input:focus + .hiddenlabel-main {
    color: #66afe9 !important; }
