/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.introtext {
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
  color: #000;
  padding: 20px 0;
  font-size: 16px; }

.infobox {
  text-align: center; }

.infotext {
  color: #8a8a8a;
  font-size: 0.8em;
  padding: 20px 0;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  display: block; }

.textfieldcontainer {
  position: relative; }

.input {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #ccc;
  line-height: 27px;
  font-size: 14px; }
  .input.name {
    border-top-right-radius: 0.28571429rem;
    border-top-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .input.password {
    margin-top: -1px;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0.28571429rem;
    border-bottom-left-radius: 0.28571429rem; }

.login-footer {
  margin: -15px -15px;
  padding: 24px 15px; }
  .login-footer:before, .login-footer:after {
    content: " ";
    display: table; }
  .login-footer:after {
    clear: both; }

.language-select {
  float: left;
  width: 50%; }

.submit-button {
  margin: 20px 0;
  width: 100%; }

.forgot-link {
  position: absolute;
  right: 13px;
  top: 57px;
  color: #87bc2f; }
  .forgot-link:hover {
    text-decoration: underline;
    color: #87bc2f; }

.errormessage {
  margin-top: 20px; }
