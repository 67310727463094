/**
 * base.scss
 * Imports all reusable parts - variables, functions, mixins - which can
 * be safely imported in local css files.
 */
/**
 * The main configuration of Bootstrap.
 */
.input-container {
  margin-left: -6;
  margin-right: -6;
  display: flex;
  align-items: stretch; }
  .input-container:before, .input-container:after {
    content: " ";
    display: table; }
  .input-container:after {
    clear: both; }

.form-container {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 1 50%;
  max-height: 80vh;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid #d6d7da; }

.navigation-tabs {
  margin-top: -3px;
  padding: 12px 15px !important;
  background-color: #edeeeb; }
  .navigation-tabs li {
    padding: 10px 20px 10px 0; }
  .navigation-tabs li a {
    color: #000;
    padding: 0 !important; }
  .navigation-tabs li a:hover {
    background: transparent;
    border-bottom: 1px solid #919191 !important; }

.fullwidth {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 992px) {
    .fullwidth {
      float: left;
      width: 100%; } }

.additional-container {
  flex: 1 0 50%;
  float: none;
  margin-top: -62px; }
  @media screen and (max-width: 950px) {
    .additional-container {
      display: none; } }
