.repetitiveModalBody {
  padding: 15px; }

.repetitiveModalContainer h4 {
  font-size: 14px;
  font-weight: normal;
  border-bottom: 1px solid black;
  display: inline-block;
  padding: 0px 0;
  margin-left: 5px;
  margin: -5px 0 5px 5px; }

.repetitiveModalContainer .title {
  display: block; }

.repetitiveModalContainer .titlecontainer {
  padding: 15px 17px 0px 17px;
  margin: 0 auto; }
  .repetitiveModalContainer .titlecontainer span {
    font-size: 16px;
    color: #000; }
