.chat-item-list {
  list-style-type: none;
  margin: 0;
  padding: 5px 0 0 0;
  height: 100%;
  overflow: auto; }

.chat-item {
  display: block;
  height: 32px;
  padding-left: 12px; }

.chat-item a,
.chat-item a:hover,
.chat-item a::selection {
  text-decoration: none;
  color: #333; }

.chat-icon {
  float: right;
  width: 28px;
  line-height: 32px; }
  .chat-icon span:before {
    font-size: 10px !important;
    color: #599b05; }

.message-badge {
  float: right;
  margin-right: 10px;
  line-height: 32px; }

.chat-user {
  line-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
