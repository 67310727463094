.additional-info .ui.checkbox input {
  position: absolute !important; }

.additional-info .ui.checkbox label {
  padding-left: 23px;
  font-size: 14px !important;
  color: #9e9e9e;
  transition: 0.5s color; }

.additional-info .ui.checkbox input:checked + label {
  color: #000 !important; }

.additional-info .ui.checkbox.switch.checked label {
  background: #87bc2f !important;
  color: #fff !important; }

.additional-info .ui.checkbox.toggle {
  top: -1px;
  left: -8px; }
  .additional-info .ui.checkbox.toggle label:after,
  .additional-info .ui.checkbox.toggle label:before {
    transform: scale(0.7); }
  .additional-info .ui.checkbox.toggle label {
    color: #9e9e9e;
    transition: 0.5s color;
    line-height: 14px; }
  .additional-info .ui.checkbox.toggle input:checked + label {
    color: #000; }
  .additional-info .ui.checkbox.toggle input:checked ~ .box:before,
  .additional-info .ui.checkbox.toggle input:checked ~ label:before {
    background-color: #87bc2f !important;
    width: 2.8rem !important; }
  .additional-info .ui.checkbox.toggle input:checked ~ .box:after,
  .additional-info .ui.checkbox.toggle input:checked ~ label:after {
    left: 1.1rem !important; }
  .additional-info .ui.checkbox.toggle input:focus:checked ~ .box:before,
  .additional-info .ui.checkbox.toggle input:focus:checked ~ label:before {
    background-color: #6a9325 !important; }
  .additional-info .ui.checkbox.toggle .box:before,
  .additional-info .ui.checkbox.toggle label:before {
    width: 2.8rem !important; }

.additional-info .ui.checkbox input:focus ~ .box:before,
.additional-info .ui.checkbox input:focus ~ label:before {
  background: #fff;
  border-color: #87bc2f !important; }

.form-group .ui.selection.dropdown {
  border: 1px solid #ccc; }
  .form-group .ui.selection.dropdown:hover {
    border: 1px solid #ccc; }
    .form-group .ui.selection.dropdown:hover.active {
      border: 1px solid #87bc2f; }
      .form-group .ui.selection.dropdown:hover.active + div {
        color: #87bc2f; }
  .form-group .ui.selection.dropdown.active {
    border: 1px solid #87bc2f; }
    .form-group .ui.selection.dropdown.active:hover {
      border: 1px solid #87bc2f; }
  .form-group .ui.selection.dropdown .menu.transition.visible {
    border: 1px solid #87bc2f; }
    .form-group .ui.selection.dropdown .menu.transition.visible > div {
      padding-left: 15px !important; }

.form-control {
  box-shadow: none !important; }

.ui.disabled.selection.dropdown,
input:disabled {
  background: transparent !important;
  border-bottom: 1px solid #ccc;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  opacity: 1; }

.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label,
.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label {
  color: #ccc !important; }

.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label,
.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label {
  opacity: 0.9 !important; }
