.tourSubForm {
  padding-top: 20px; }
  .tourSubForm h4 {
    font-size: 14px;
    font-weight: normal;
    border-bottom: 1px solid black;
    display: inline-block;
    padding: 0px 0;
    margin-left: 5px;
    margin: -5px 0 5px 5px; }
  .tourSubForm .title {
    display: block;
    margin-bottom: 15px; }
  .tourSubForm .titlecontainer {
    padding: 0px 17px 0px;
    margin: 0 auto; }
    .tourSubForm .titlecontainer span {
      font-size: 16px;
      color: #000; }
  .tourSubForm .fields {
    padding: 15px 15px 20px;
    margin: 0 auto; }
    .tourSubForm .fields div {
      margin: -1px 0; }
    .tourSubForm .fields div:first-child input {
      border-top-left-radius: 0.28571429rem;
      border-top-right-radius: 0.28571429rem; }
    .tourSubForm .fields div:last-child input {
      border-bottom-left-radius: 0.28571429rem;
      border-bottom-right-radius: 0.28571429rem; }
  .tourSubForm .checks {
    padding: 5px 15px 5px;
    margin: 0 auto; }
  .tourSubForm .textlink {
    background: transparent;
    border: none;
    color: #808080;
    float: left;
    padding: 7px 11px;
    cursor: pointer; }
    .tourSubForm .textlink:hover {
      text-decoration: underline;
      background: transparent;
      border: none; }
  .tourSubForm .checkboxes {
    float: right;
    width: 30%;
    padding: 0 10px 5px 0; }
    .tourSubForm .checkboxes div {
      width: 100%; }
      .tourSubForm .checkboxes div > span {
        padding-left: 15px;
        top: -2px;
        position: relative; }
  .tourSubForm .tour-form-footer {
    clear: both; }
