.timeperiod-wrapper {
  position: relative; }
  .timeperiod-wrapper .hiddenlabel-sup {
    position: absolute;
    z-index: 1;
    left: 5px;
    top: 1px;
    font-size: 0.7em;
    color: #9e9e9e;
    transition: 0.5s all; }
  .timeperiod-wrapper:focus {
    outline: 0;
    border: none; }
    .timeperiod-wrapper:focus .hiddenlabel-sup {
      color: #87bc2f; }
    .timeperiod-wrapper:focus .noUi-origin:first-child {
      background: #87bc2f !important; }
    .timeperiod-wrapper:focus .noUi-origin:last-child {
      background: #fff !important;
      box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb; }
    .timeperiod-wrapper:focus .noUi-handle {
      opacity: 1;
      width: 14px;
      height: 14px;
      left: -7px;
      top: -6px;
      transition: all 0.275s, opacity 0.05s; }
  .timeperiod-wrapper input:focus + .hiddenlabel-sup {
    color: #87bc2f; }
  .timeperiod-wrapper.single .link {
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem; }
  .timeperiod-wrapper input[type="text"] {
    position: relative;
    width: 100%;
    height: 38px;
    top: -1px;
    border-top-color: #e4e4e4;
    border: 1px solid #ccc;
    border-top-left-radius: 0.28571429rem;
    border-top-right-radius: 0.28571429rem;
    text-align: center;
    line-height: 100%;
    vertical-align: middle;
    padding-bottom: 0;
    padding-top: 9px;
    padding-left: 4px;
    line-height: 28px; }
    .timeperiod-wrapper input[type="text"].enabled {
      background: #fff !important;
      border: 1px solid #ccc !important; }
  .timeperiod-wrapper .link {
    padding: 5px;
    width: 100%;
    display: inline-block;
    background: white;
    border: 1px solid #ccc;
    line-height: 26px;
    border-top-left-radius: 0.28571429rem;
    border-top-right-radius: 0.28571429rem; }
    .timeperiod-wrapper .link img {
      position: relative;
      margin-right: 6px;
      margin-left: 2px;
      top: -2px; }
    .timeperiod-wrapper .link span {
      cursor: pointer;
      color: #9f9f9f;
      font-size: 13px; }
      .timeperiod-wrapper .link span:hover {
        text-decoration: underline; }
      .timeperiod-wrapper .link span.additional {
        float: right;
        font-size: 85%; }
  .timeperiod-wrapper:hover .clearbutton {
    opacity: 1; }
  .timeperiod-wrapper.disabled.inactive {
    display: none; }
  .timeperiod-wrapper.disabled .clearbutton {
    display: none; }
  .timeperiod-wrapper.disabled .noUi-background {
    background: #eeeeee;
    box-shadow: 0;
    border-left: 0;
    border-right: 0; }
  .timeperiod-wrapper.disabled .noUi-handle,
  .timeperiod-wrapper.disabled .noUi-draggable {
    cursor: default;
    background-color: #b1b1b1 !important; }
  .timeperiod-wrapper.disabled .noUi-handle {
    display: none !important; }
  .timeperiod-wrapper.disabled input.disabled:focus + .hiddenlabel-sup,
  .timeperiod-wrapper.disabled input.disabled + .hiddenlabel-sup,
  .timeperiod-wrapper.disabled .hiddenlabel-sup {
    color: #9e9e9e; }
  .timeperiod-wrapper .clearbutton {
    position: absolute;
    opacity: 0;
    top: 11px;
    right: 7px;
    border-radius: 50%;
    color: #fff;
    background: #bfbfbf;
    height: 15px;
    width: 15px;
    font-size: 1.1rem;
    line-height: 13.5px;
    vertical-align: middle;
    padding-left: 3px;
    padding-top: 1px;
    cursor: pointer;
    transition: 0.5s background;
    transition: 0.2s opacity; }
    .timeperiod-wrapper .clearbutton:hover {
      background: #9e9e9e; }

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr;
  margin-top: -5px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: none;
  border-bottom: none; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #ccc;
  /* Fix 401 */ }

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

.noUi-handle {
  position: relative;
  z-index: 1; }

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10; }

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 4px; }

.timeslot:hover .noUi-horizontal .noUi-handle {
  opacity: 1;
  width: 14px;
  height: 14px;
  left: -7px;
  top: -6px;
  transition: all 0.275s, opacity 0.05s; }

.noUi-horizontal .noUi-handle {
  opacity: 0;
  width: 0;
  height: 0;
  left: 0;
  top: 1px;
  border-radius: 50%;
  transition: all 0.275s, opacity 0.15s ease 0.1s;
  cursor: col-resize; }

.noUi-horizontal .noUi-handle .noUi-tooltip {
  display: none; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

/* Styling;
 */
.noUi-background {
  background: #fafafa;
  box-shadow: inset 0 1px 1px #f0f0f0; }

.timeslot:hover .noUi-origin:first-child {
  background: #87bc2f !important; }

.timeslot:hover .noUi-origin:last-child {
  background: #fff !important;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb; }

.noUi-connect {
  background: #b1b1b1;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms; }

.noUi-target {
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb; }

.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize; }

.noUi-vertical .noUi-draggable {
  cursor: n-resize; }

.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb; }

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb; }

/* Handle stripes;
 */
.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #b8b8b8; }

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed; }
